import styled from "styled-components";

type CardContainerProps = {
  padding?: string;
  cols?: number;
};

const CardContainer = styled.div<CardContainerProps>`
  display: grid;
  grid-gap: 2rem;
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ cols }) => cols && `grid-template-columns: repeat(${cols}, 1fr);`}
`;

export default CardContainer;
